define("discourse/plugins/discourse-livestream/discourse/services/embeddable-chat", ["exports", "@ember/service", "discourse/plugins/chat/discourse/services/chat"], function (_exports, _service, _chat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LIVESTREAM_TAG_NAME = void 0;
  const LIVESTREAM_TAG_NAME = _exports.LIVESTREAM_TAG_NAME = "livestream";
  class EmbeddableChat extends _chat.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    canRenderChatChannel(topicController) {
      let mobileViewAllowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.topicController = topicController;
      if (this.site.mobileView === mobileViewAllowed && this.siteSettings.chat_enabled && this.currentUser && this.userCanChat) {
        const allowedPaths = this.siteSettings.embeddable_chat_allowed_paths.split("|");
        const withinPathsAllowed = allowedPaths.some(path => this.router.currentURL.includes(path) || this.router.currentURL.startsWith(path));
        if (withinPathsAllowed && this.topicController?.model?.chat_channel_id) {
          return true;
        }
      }
      return false;
    }
    topicHasLivestreamTag(topic) {
      return topic?.tags?.some?.(tag => tag === LIVESTREAM_TAG_NAME) || false;
    }
    get chatChannelId() {
      return this.topicController?.model?.chat_channel_id;
    }
  }
  _exports.default = EmbeddableChat;
});