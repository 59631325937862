define("discourse/plugins/discourse-livestream/discourse/components/modal/mobile-embeddable-chat-modal", ["exports", "@glimmer/component", "@ember/controller", "@ember/service", "discourse/components/d-modal", "discourse/plugins/discourse-livestream/discourse/components/embeddable-chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _controller, _service, _dModal, _embeddableChatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MobileEmbeddableChatModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "embeddableChat", [_service.inject]))();
    #embeddableChat = (() => (dt7948.i(this, "embeddableChat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topicController", [(0, _controller.inject)("topic")]))();
    #topicController = (() => (dt7948.i(this, "topicController"), void 0))();
    get shouldRender() {
      return this.embeddableChat.canRenderChatChannel(this.topicController, true);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="livestream-chat-modal"
          @hideHeader={{true}}
        >
          <:body>
            {{#if this.shouldRender}}
              <EmbeddableChatChannel
                @chatChannelId={{this.embeddableChat.chatChannelId}}
              />
            {{/if}}
          </:body>
        </DModal>
      
    */
    {
      "id": "7gDiBF5c",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"livestream-chat-modal\"]],[[\"@closeModal\",\"@hideHeader\"],[[30,1],true]],[[\"body\"],[[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"          \"],[8,[32,1],null,[[\"@chatChannelId\"],[[30,0,[\"embeddableChat\",\"chatChannelId\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-livestream/discourse/components/modal/mobile-embeddable-chat-modal.js",
      "scope": () => [_dModal.default, _embeddableChatChannel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MobileEmbeddableChatModal;
});